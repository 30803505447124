import { rgba } from 'polished';

// CONSTANTS
import { MAIN_BLACK, ROYAL_BLUE_2, SANTAS_GRAY, WHITE } from '@/constants/colors';

export const StreamingRewardsAppClaimStatus = {
  AVAILABLE: 'available_to_claim',
  UNAVAILABLE: 'not_available_to_claim',
  CLAIMED: 'claimed',
};

export const StreamingRewardsType = {
  VIRTUAL_CARD: 'virtual_card',
  SINGLE_APP_VIRTUAL_CARD: 'single_app_virtual_card',
  MULTIPLE_APPS_VIRTUAL_CARD: 'multiple_apps_virtual_card',
  MULTIPLE_APPS_REFRESHABLE_VIRTUAL_CARD: 'multiple_apps_refreshable_virtual_card',
  MULTIPLE_APPS_REFRESHABLE_VIRTUAL_CARD_DOWNTOWN:
    'multiple_apps_refreshable_virtual_card_downtown',
  USER_CARD: 'user_card',
  TRIAL: 'trial',
  PROMOCODE: 'promocode',
  VIRTUAL_CARD_PROMOCODE: 'virtual_card_with_promo_code',
  MULTIPLE_APPS_VIRTUAL_CARD_PROMOCODE: 'multiple_apps_virtual_card_with_promo_code',
  MULTIPLE_APPS_REFRESHABLE_VIRTUAL_CARD_PROMOCODE:
    'multiple_apps_refreshable_virtual_card_with_promo_code',
};

export const multipleAppsRewardTypes = [
  StreamingRewardsType.MULTIPLE_APPS_REFRESHABLE_VIRTUAL_CARD,
  StreamingRewardsType.MULTIPLE_APPS_REFRESHABLE_VIRTUAL_CARD_DOWNTOWN,
  StreamingRewardsType.MULTIPLE_APPS_VIRTUAL_CARD,
  StreamingRewardsType.MULTIPLE_APPS_VIRTUAL_CARD_PROMOCODE,
  StreamingRewardsType.MULTIPLE_APPS_REFRESHABLE_VIRTUAL_CARD_PROMOCODE,
];

export const AnimCardPosition = {
  TOP: 'top',
  STICKY: 'sticky',
  BOTTOM: 'bottom',
};

export const STEPS_COLORS = {
  default: [MAIN_BLACK, rgba(SANTAS_GRAY, 0.2), MAIN_BLACK, rgba(MAIN_BLACK, 0.1)],
  active: [WHITE, ROYAL_BLUE_2, ROYAL_BLUE_2, rgba(MAIN_BLACK, 0.1)],
  completed: [ROYAL_BLUE_2, rgba(ROYAL_BLUE_2, 0.2), ROYAL_BLUE_2, rgba(MAIN_BLACK, 0.1)],
}; // [color, backgroundColor, textColor, lineColor]

export const SIDEBAR_STEPS_COLORS = {
  default: [MAIN_BLACK, rgba(SANTAS_GRAY, 0.2), MAIN_BLACK, rgba(MAIN_BLACK, 0.1)],
  active: [WHITE, MAIN_BLACK, MAIN_BLACK, rgba(MAIN_BLACK, 0.1)],
  completed: [MAIN_BLACK, rgba(MAIN_BLACK, 0.2), MAIN_BLACK, rgba(MAIN_BLACK, 0.1)],
}; // [color, backgroundColor, textColor, lineColor]

export const FundsPaymentType = {
  ONE_TIME: 'one_time_payment',
  MONTHLY: 'monthly_payment',
};

export const urlTypes = {
  STREAMING_REWARD: 'streaming-reward',
  STREAMING_CHOICE: 'streaming-choice',
};

export const processingMessage = 'Your payment is processing.';
export const paymentMethodMessage = 'Your payment was not successful, please try again.';
export const defErrorMessage = 'Something went wrong.';

export const rewardGroups = {
  VIRTUAL_CARDS: 'virtual_cards',
  VIRTUAL_CARDS_PROMO_CODES: 'virtual_cards_with_promo_codes',
  TRIALS: 'trials',
  PROMO_CODES: 'promo_codes',
};

export default {
  StreamingRewardsAppClaimStatus,
  StreamingRewardsType,
  AnimCardPosition,
  STEPS_COLORS,
  rewardGroups,
};
