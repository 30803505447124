// -- Endpoints --
export const HEALTH_CHECK = 'health-check';
export const EMAIL_UNSUBSCRIBE = 'emails/unsubscribe';
export const CREATE_FEEDBACK = 'feedback/create';
export const DICTIONARY = 'dictionary';
export const MAGIC_LINK_USE = 'magic-link/use/:token';

// AUTH
export const AUTH_GET_USER = 'auth/me';
export const AUTH_LOGIN = 'auth/login';
export const AUTH_LOGIN_SAML = 'auth/login-saml';
export const AUTH_REGISTER = 'auth/register';
export const AUTH_REGISTER_CURIOSITY_STREAM = 'curiosity-stream/register';
export const AUTH_REFRESH_TOKEN = 'auth/refresh';
export const AUTH_RP_SEND_EMAIL = 'auth/password/email';
export const AUTH_RP_UPDATE = 'auth/password/reset';

// USER
export const USER_EDIT = 'user/edit';
export const USER_CHECK_FIELD = 'user/check-field';
export const USER_CHECK_UNIQUE_FIELD = 'user/check-unique-field';
export const USER_RESULTS = 'forms/all';
export const USER_APPS_SEARCH = 'apps/search';
export const USER_GET_APPS = 'apps-list';
export const USER_GET_RECOMMENDED_APPS = 'promo-apps';
export const USER_CHANGE_APP_PRICE = 'apps-list/apps/change-price';
export const USER_GET_RECOMMENDATIONS = 'user/recommendations/results';
export const USER_CARDS = 'user/cards';
export const USER_CARDS_SETUP_INTENT = 'user/cards/setup-intent';
export const USER_CARDS_GET_STATUS = 'user/cards/status';
export const USER_UPDATE_FIRST_NAME = 'user/first-name';
export const USER_GET_CONTENT_COUNT = 'user/content-count';
export const USER_GET_POPULAR_CONTENT = 'my-apps/popular-content';
export const USER_GET_IO_BY_UNIQUE_ID = 'internet-option/user-result/:unique_id';

// USER PAYMENT HISTORY
export const USER_STREAMING_REWARD_GET = 'user/streaming-reward';
export const USER_STREAMING_REWARD_WIDGET_GET = `${USER_STREAMING_REWARD_GET}/widget`;
export const USER_PAYMENT_HISTORY_GET = `${USER_STREAMING_REWARD_GET}/payments`;
export const USER_CHANGE_PASSWORD = 'user/change-password';

// PAGES
export const GET_PAGES_CONTENT = 'pages/:page';

// WIZARD
export const GET_SKIP_QUESTIONS = 'partners/skip-questions/:slug';
export const GET_UNIQUE_ID = 'forms/unique_id';
export const ASYNC_INTERNET_OPTIONS = 'bundles/async/internet-option/:unique_id';
export const GET_PARTNER = 'partners/:slug';

// PARTNERS
export const PARTNER_BY_DOMAIN = 'partners/get-partner-by-domain';

// COUNTIES
export const GET_COUNTIES = 'counties/zipcode';
export const GET_COUNTIES_BY_ADDRESS = 'counties/address';

// CHANNELS
export const GET_CHANNEL = 'channels/:slug';
export const GET_CHANNELS = 'channels';
export const GET_CHANNELS_TOP = 'channels/top';
export const CHANNELS_GET_FILTERS = 'channels/filters';
export const CHANNELS_SUBMIT_FORM = 'forms';
export const CHANNELS_GET_FORM_DATA = 'forms/:unique_id';
export const CHANNELS_AUTOCOMPLETE = 'channels/autocomplete';

// RESULTS
export const GET_RESULTS = 'bundles/result/:unique_id';
export const GET_CSR_RESULTS = 'bundles/csr-result/:unique_id';
export const RESULTS_ADD_EMAIL = 'forms/email';
export const RESULTS_SENT_TOKEN = 'forms/send-token/:email';
export const APPS_BY_IDS = 'apps/by-ids';
export const SERVICES_CHANNELS_BY_IDS = 'apps/channels/:ids/:dma_id';

// DISCOVER
export const GET_DISCOVER = 'explore';
export const GET_DISCOVER_FILTERS = 'explore/dictionaries';
export const GET_DISCOVER_SEARCH = 'explore/search';

// APP DETAILS
export const APP_DETAILS = 'apps/:slug';
export const APP_CHANNELS = 'channels/app/:slug';
export const APP_GET_RATINGS = 'apps/ratings/:slug';
export const APP_GET_REVIEWS = 'apps/reviews/:slug';
export const APP_ADD_REVIEW = 'apps/reviews/:app_id';
export const APP_GET_EXTERNAL_LINK = 'apps/clean-link/:slug';
export const APP_LIST_CHANGE_STATUS = 'apps-list/apps/change-status';
export const APP_LIST_REMOVE = 'apps-list/:app_id/remove';
export const APP_CONTACT_US_REQUEST_DEMO = 'contact-us/demo/request';
export const APP_GET_DOWNLOAD_APP_LINK_VIA_SMS = 'notifications/sms';

// PLAN DETAILS
export const PLAN_DETAILS = 'services/bundles/:bundle_id';

// MOVIE DETAILS
export const MOVIE_DETAILS = 'movies/:movie_id';
export const MOVIE_SIMILAR = 'movies/similar/:movie_id';
export const MOVIE_STREAMING_DATA = 'movies/streaming-data/:movie_id';

// SHOW DETAILS
export const SHOW_DETAILS = 'shows/:show_id';
export const SHOW_SIMILAR = 'shows/similar/:show_id';
export const SHOW_STREAMING_DATA = 'shows/streaming-data/:show_id';

// CONTENT
export const CONTENT_SUBSCRIBTION = 'subscription/content';
export const CONTENT_MEDIA = 'content/:content_id/media';
export const CONTENT_CHANGE_STATUS = 'content/:content_id/change-status';

// INTERNET OPTION
export const RESULTS_INTERNET_OPTION = 'bundles/internet-option/:internet_option_id/:unique_id';

// LOCATIONS
export const GET_LOCATIONS = 'locations';

// INTERNET OFFERS
export const INTERNET_OFFERS_SEND_ADDRESS = 'internet-option/address';
export const INTERNET_OFFERS_SAVE_ANSWERS = 'internet-option/save-answers/:io_id';
export const INTERNET_OFFERS_GET_RESULT = 'internet-option/result/:io_id';
export const INTERNET_PARTNER_OFFER_GET_DATA = 'partner-offers';
export const INTERNET_PARTNER_OFFER_CLAIM = 'partner-offers/:offer_id/claim';
export const INTERNET_OFFERS_SUBSCRIBE = 'internet-option/subscribe';
export const INTERNET_OFFERS_CHECK_SUBSCRIPTION = 'internet-option/check-subscription';

// WATCH NEXT
export const WATCH_NEXT_GET_DICTIONARY = 'recommendations/dictionary';
export const WATCH_NEXT_INIT_FORM = 'recommendations/empty';
export const WATCH_NEXT_SUBMIT_FORM = 'recommendations/:wn_id';
export const WATCH_NEXT_GET_RESULTS = 'recommendations/:wn_id';
export const WATCH_NEXT_SUBMIT_VOTE = 'recommendations/:wn_id/content/:content_id/vote';
export const WATCH_NEXT_ADD_EMAIL = 'recommendations/:wn_id/add-email';
export const WATCH_NEXT_SEARCH = 'recommendations/:wn_id/content/search';
export const WATCH_NEXT_ADD_SUGGESTION = 'recommendations/:wn_id/content/recommend';
export const WATCH_NEXT_CONTENT = 'recommendations/:wn_id/content/:content_id';
export const WATCH_NEXT_COMPLETE = 'recommendations/:wn_id/complete';
export const WATCH_NEXT_DELETE_CONTENT = 'vote-list/:wn_id/content/:content_id';
export const WATCH_NEXT_REFRESH_RECOMMENDS = 'vote-list/:wn_id/recommendations/refresh';
export const WATCH_NEXT_PROFILE_LIST = 'vote-list';
export const WATCH_NEXT_CONTENT_LIST = 'vote-list/:wn_id';
export const WATCH_NEXT_DELETE_LIST = 'recommendations/:wn_id';

// WATCH LIST
export const WATCH_LIST_FILTERS = 'watch-list/dictionary';
export const WATCH_LIST_SEARCH = 'watch-list/search';
export const WATCH_LIST_INFO = 'watch-list/:id';
export const WATCH_LIST_CONTENT = 'watch-list/:id/content';
export const WATCH_LIST_COPY_CONTENT = 'watch-list/:id/copy';
export const WATCH_LIST_GET_LIST = 'watch-list/all';
export const WATCH_LIST_CREATE = 'watch-list';
export const WATCH_LIST_DELETE = 'watch-list/:id';
export const WATCH_LIST_UPDATE = 'watch-list/:id';
export const WATCH_LIST_ADD_CONTENT = 'watch-list/content/:content_id/add';
export const WATCH_LIST_REMOVE_CONTENT = 'watch-list/content/:content_id/remove';
export const WATCH_LIST_ADD_CONTENTS_MULTIPLE_WATCHLISTS = 'watch-list/multi/watchlist-content';
export const WATCH_LIST_ADD_CONTENT_DEF_LIST = 'watch-list/content/:content_id/want-to-watch';

// ONBOARDING
export const ONBOARDING_APPS = 'apps-list/onboard';
export const ONBOARDING_APPS_COMPLETE = 'apps-list/onboard/complete';

// SUBSCRIPTION
export const SUBSCRIPTION_DETAILS = 'apps/:slug/subscription';
export const SUBSCRIPTION_DICTIONARY = 'apps/:slug/subscription/dictionary';
export const SUBSCRIPTION_ID = 'subscription/:id';
export const SUBSCRIPTION_REGISTRATION = 'subscription-user/register';
export const SUBSCRIPTION_REGISTER_SERVICE = 'subscription-user/register-service';
export const SUBSCRIPTION_PAYMENT = 'subscription-user/payment';
export const SUBSCRIPTION_STATUS = 'subscription-user/:subscription_id/status';
export const SUBSCRIPTION_REG_SERVICE_STATUS = 'subscription-user/register-service/:type/status';
export const SUBSCRIPTION_CANCEL = 'subscription-user/:subscription_id/cancel';
export const SUBSCRIPTION_RENEW = 'subscription-user/:subscription_id/renew';
export const SUBSCRIPTION_TRANSACTIONS = 'subscription-user/transactions';
export const SUBSCRIPTION_PRODUCT_TRANSACTIONS = 'subscription-user/:id/transactions';
export const SUBSCRIPTION_PREVIEW = 'subscription-user/preview';
export const SUBSCRIPTION_CHANGE_PASSWORD = 'subscription-user/:subscription_id/change-password';

// SWIMLANES
export const SWIMLANES = 'swimlanes';
export const SWIMLANES_CONTENT_ID = 'swimlanes/content/:id';
export const SWIMLANES_GET_POPULAR_CONTENT = 'swimlanes/popular-content';

// PROMOTION
export const PROMOTION_GET_DETAILS = 'promo-companies/:promo_slug';
export const PROMOTION_GET_CODE = 'promo-companies/:promo_slug/codes/claim';
export const PROMOTION_CHECK_CODE_STATUS = 'promo-companies/:promo_slug/codes/:code';
export const PROMOTION_CHECK_AUTH_CODE = 'promo-companies/:promo_slug/authorization/:code';

// UNIVERSAL SEARCH
export const UNIVERSAL_SEARCH_GET = 'universal-search';

// STREAMING REWARDS
export const STREAMING_REWARDS_GET = 'streaming-rewards/:slug';
export const STREAMING_REWARDS_CHECK = 'streaming-rewards/:slug/claim';
export const STREAMING_REWARDS_CLAIM = 'streaming-rewards/:slug/use';
export const STREAMING_REWARDS_GET_CLAIM_INST = 'streaming-rewards/:slug/app/:app_slug';
export const STREAMING_REWARDS_GET_IMAGE_APPS = 'streaming-rewards/:slug/apps';
export const STREAMING_REWARDS_GET_CLIENT_SECRET =
  'user/streaming-reward/payment-intent-client-secret';
export const STREAMING_REWARDS_COMPLETE_REDEEM = '/user/streaming-reward/complete-redeem';

// STREAMING REWARD FUNDS
export const STREAMING_REWARDS_FUNDS_PAYMENT = 'user/streaming-reward/top-up';
export const STREAMING_REWARDS_FUNDS_RECURRING_PAYMENT_CANCEL = `${STREAMING_REWARDS_FUNDS_PAYMENT}/cancel`;
export const STREAMING_REWARDS_FUNDS_PAYMENT_CANCEL = `${STREAMING_REWARDS_FUNDS_PAYMENT}/:transaction_id`;
export const STREAMING_REWARDS_FUNDS_PAYMENT_STATUS = `${STREAMING_REWARDS_FUNDS_PAYMENT}/:transaction_id/status`;
export const STREAMING_REWARDS_FUNDS_GET_PAYMENT_STATUS = `${STREAMING_REWARDS_FUNDS_PAYMENT}/status`;

// VIRTUAL CARD DATA
export const VIRTUAL_CARD_GET_EXPANDED_DATA = 'virtual-card/expand';

// VIRTUAL CARD BUY PRODUCT
export const VIRTUAL_CARD_BUY_PRODUCT = 'store/products/buy';
export const VIRTUAL_CARD_GET_PRODUCTS = 'store/products';

// CHANNEL LINEUP
export const CHANNEL_LINEUP_GET_DICTIONARY = 'channels/lineup/:slug/dictionary';
export const CHANNEL_LINEUP_GET_TOP = 'channels/lineup/:slug/top';
export const CHANNEL_LINEUP_SEARCH = 'channels/lineup/:slug/search';
export const CHANNEL_LINEUP_GET_CHANNELS = 'channels/lineup/:slug';
export const CHANNEL_LINEUP_GET_REPLACEMENTS_CHANNELS = 'channels/lineup/:slug/missed';

// SHARE INFO
export const SHARE_INFO_GET = 'bundles/result/:unique_id/share-info';

// SLING BEST PACKAGE
export const SBP_GET_PACKAGE = 'sling/packages/calculate';

// _____ SEGMENT WEBHOOK
export const SEGMENT_WEBHOOK = 'webhooks/:key';

// _____ IP-API
export const GET_IP_INFO = 'json';
