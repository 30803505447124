import { format, parse } from 'date-fns';
import { stringifyUrl } from 'query-string';

// CONSTANTS
import { ROUTE_SUBSCRIPTION } from '@/constants/routes';
import { TransactionType } from '@/constants/subscription';

// UTILITY
import { convertToDate } from '@/utility/date';
import { getLink } from '@/utility/routes';
import bugsnag from '@/utility/bugsnagClient';

// LOCALIZATION
import { t } from '@/locale/i18n';

/** @type {(last4Symbols: number|string)=>string} */
export function getSecureCardNumberValue(last4Symbols) {
  return `**** **** **** ${last4Symbols}`;
}

/** @type {(expMonth: number,expYear: number)=>string} */
export function formatCardExpValue(expMonth, expYear) {
  const date = parse(`${expMonth} ${expYear}`, 'MM yyyy', new Date());

  return format(date, 'MM/yy');
}

/** @type {(date: string,separator?:string)=>string} */
export function formatSubscriptionDate(date, separator = '') {
  return format(convertToDate(date), `MMM dd${separator} yyyy`);
}

/** @type {(product: object)=>object|undefined} */
export function getPlan(product) {
  try {
    return product.plans.find(plan => plan.interval === 'month');
  } catch (e) {
    const error = new Error(`getPlan error, product - ${product}`, { cause: e });

    bugsnag.notify(error);
    window.newrelic?.noticeError(error);

    return '';
  }
}

/** @type {(products: array, id: number|string)=>object|undefined} */
export function getProductByPlanId(products, id) {
  return products.find(product => product.plans.find(plan => plan.id === +id));
}

/** @type {(plans: array, id: number|string)=>object} */
export function getPlanById(plans, id) {
  return plans.find(plan => plan.id === +id);
}

/** @type {(addons: array, ids: array)=>array} */
export function formatAddons(addons) {
  return addons.map(addon => {
    const updatedAddon = { ...addon, included_in: null };

    for (const selAddon of addons) {
      if (selAddon.addOns.length && selAddon.addOns.includes(addon.id)) {
        updatedAddon.included_in = selAddon.name;

        break;
      }
    }

    return updatedAddon;
  });
}

/** @type {(addons: array, ids: array)=>array} */
export function getAddonsByIds(addons, ids) {
  const newAddons = ids
    .map(id => addons.find(addon => addon.plans.find(plan => plan.id === +id)))
    .filter(Boolean);

  return formatAddons(newAddons);
}

/** @type {(addons: array)=>array} */
export function getAddonsIds(addons) {
  return addons.map(addon => addon.plans[0].id);
}

// eslint-disable-next-line
/** @type {(link:string, plan_id: number|string, addonsData: [], zipcode: any, queryParams?: {})=>string} */
export function createLinkForSubscription(link, plan_id, addonsData, zipcode, queryParams = {}) {
  const addons = Array.isArray(addonsData)
    ? addonsData.map(addon => addon.plan_id || '').join(',')
    : typeof addonsData === 'string'
    ? addonsData
    : '';

  return stringifyUrl(
    {
      url: link,
      query: {
        plan_id: plan_id || '',
        addons: (plan_id && addons) || '',
        zipcode: zipcode || '',
        ...queryParams,
      },
    },
    { skipEmptyString: true },
  );
}

/** @type {(slug: string, plad_id: number|string, addonsData: array)=>string} */
export function getSubscriptionLink(slug, plan_id, addonsData, zipcode) {
  const link = getLink(ROUTE_SUBSCRIPTION, { slug });

  return createLinkForSubscription(link, plan_id, addonsData, zipcode);
}

/** @type {(plan: {}, addons: any[] | null)=>number} */
export function getTotalPrice(plan, addons) {
  const addOnsPrice = addons?.length
    ? addons.reduce((acc, item) => {
        const itemPrice = !item.included_in ? +item.plans[0].price : 0;

        return acc + itemPrice;
      }, 0)
    : 0;

  const planPrice = plan ? Number(plan.discount_price || plan.price) : 0;

  return planPrice + addOnsPrice;
}

/** @type {(paymentType: string)=>string} */
export function getPaymentTypeCopy(paymentType) {
  let text;

  switch (paymentType) {
    case TransactionType.SUBSCRIPTION:
      text = t('common.paymentType.subscription');
      break;
    case TransactionType.SINGLE_CHARGE:
      text = t('common.paymentType.single_charge');
      break;
    case TransactionType.TOP_UP:
      text = t('common.paymentType.top_up');
      break;
    case TransactionType.REFUND:
      text = t('common.paymentType.refund');
      break;
    default:
      text = paymentType;
      break;
  }

  return text;
}
