export default {
  title: 'Streaming Reward Card',
  description:
    'Use your Streaming Rewards Card from MyBundle to subscribe and manage to your favorite streaming TV and video services',
  instructionLoading: 'Please wait for instructions loading',
  contact: 'For any questions or help, please contact ',
  howItWorks: {
    title: 'How it works',
    useReward: 'How to use your Streaming Rewards Card?',
  },
  balance: {
    balance: 'Available Balance',
    nextBalanceUpdate: 'Next Balance Update',
    nextMonthlyPayment: 'Next monthly payment of <b>{{price}}</b>',
    title: 'Streaming Rewards Card Balance',
    subtitle: '{{name}} funds amount updates to {{price}} every month',
    allocated: 'Funds in use',
    userFunds: 'Personal funds',
    partnerFunds: '{{name}} funds',
    failedChargeErrorMsg:
      "We we're unable to charge your card for your recurring payment. To reinstate you monthly payments please add funds below.",
    available: 'Streaming rewards available balance',
  },
  cancelDialog: {
    title: 'Are you sure you want to cancel next monthly payment?',
  },
  bannerFunds: {
    title: 'Not enough funds to pay for a subscription?',
    subtitle: 'Add funds with your credit or debit card to pay for new subscriptions',
  },
  addedFundsDialog: {
    title: 'Credits were successfully added',
    subtitle: '<b>{{price}}</b> was added to your reward card',
    subtitle_monthly:
      '<b>{{price}}</b> a month has been added to your rewards card. You will be charged next on <b>{{date}}</b>',
  },
  services: {
    title: 'Choose your streaming service',
    subtitle: 'Sign up using your Streaming Rewards Card to get 12 months free',
    noData: 'There are no offers available right now, check back again soon',
  },
  card: {
    title: 'Streaming Rewards Card Credits',
    balance: 'Card Balance',
    balanceAlert: 'The balance will be updated on',
    copyCardNumber: 'Card number copied',
    billing: 'Use this Billing Address',
    copy: {
      title: 'Click to copy',
      card: 'Card number copied',
      date: 'Exp. Date copied',
      cvc: 'CVC copied',
      billingAddress: 'Billing Address copied',
    },
  },
  cardBalanceTooltip: {
    title: 'Available Streaming Rewards Card Balance',
    desc: 'You have <b>{{balance}}</b> to spend on more streaming services',
    btnText: 'Go to My Streaming Rewards',
  },
  buyWithSRCard: 'Buy with Streaming Rewards Card',
  disney: {
    title: 'Get Your Disney+ Annual Plan!',
    subtitle:
      "A full year of Disney+ completely FREE.   From new releases, to your favorite classics and exclusive Originals, there's something for everyone, all ad free.",
    features: {
      title: 'Disney+ features',
      feature1: {
        title: 'Disney+ has your favorite stories',
        subtitle:
          'The Streaming home of Disney, Pixar, Marvel, Star Wars, National Geographic and more! ',
      },
      feature2: {
        title: 'Watch the way you want',
        subtitle:
          'Download any movie or series. Host virtual movie nights with GroupWatch. Stream on up to 4 devices at the same time',
      },
      feature3: {
        title: 'Available on your favorite devices',
        subtitle:
          'iPhone, iPad, Android Phones & Tablets, Amazon Fire TV, Android TV, AppleTV, Chromecast, LG TV, Roku, Samsung TV, and more',
      },
    },
    movies: {
      title: '',
      subtitle: 'An unprecedented collection of the world’s most beloved movies and TV series.',
    },
  },
  infoDialog: {
    title: '{{app_name}} will open in a new browser tab in {{seconds}} seconds',
    subtitle: "Didn't open?  click",
    addInfo:
      'Remember to use your Streaming Rewards Card below as the payment method. You can come back to this page for all the card information.',
    rightSection: {
      title: 'Streaming Rewards',
      subtitle: 'Use your Streaming rewards card to pay streaming app subscriptions',
    },
  },
  not_enough_credits: {
    title: 'Oh no! Not enough credits.',
    subtitle:
      'Looks like your Streaming Reward Card doesn’t have enough credits for this transaction. Please link your personal credit/debit card or bank account to add more credits to proceed.',
  },
  add_funds: {
    title: 'Add credits to my Streaming Reward Card',
  },
  redirect: {
    title: 'We’re opening {{name}} in a new tab for you, hang tight....',
    subtitle: 'If you haven’t been redirected in 30 seconds, please click this button',
    info: {
      title: '3. Use your streaming reward card to subscribe to the streaming service',
      subtitle:
        'Use your card information as the payment method when subscribing to a streaming service.',
    },
    no_redirect: {
      title: "Click the button to continue. We'll open {{name}} in a new window.",
    },
  },
  use_card: {
    reviewInstruction: 'Review Signup Instructions',
  },
  not_eligible: {
    title: 'Not eligible',
    subtitle: 'You are not eligible for this reward. Please logout',
  },
  instruction: {
    title: '{{name}} Signup Instructions with your\nStreaming Reward Card',
    description:
      'Please read these instructions carefully to ensure your Streaming Reward Card is used rather than\nyour personal credit card.',
  },
  hooray: {
    continueClaimBtn: 'Continue Claiming Your Reward',
    showMoviesBtn: 'Check Out What to Watch Next',
    promoInfo: 'Your exclusive promo code was {{promocode}}',
  },
  rewardNotFound: 'This reward not found',
  tourGuide: {
    viewProfile: 'View Your Profile Details',
    viewCard: 'View Your Streaming Reward Card',
  },
  createServiceAccDialog: {
    title: 'Great! Let’s set up your {{name}} account.',
    subtitle:
      'Starting now, you have {{name}} with us. Click “Continue” to set up your {{name}} account, so you can start streaming.',
  },
  promocode: {
    notification:
      'Just in case you missed your promo code, here it is: {{promocode}}. Click <a>here</a> to return to Roku Form.',
    expired: 'Your promo code was expired on {{date}}',
    dialog: {
      title: 'Your special promo code: {{promocode}}',
    },
  },
};
