export const ROUTE_ROOT = '/';
export const ROUTE_404 = '/404';
export const ROUTE_HOME = '/home';
export const ROUTE_TV = '/tv';
export const ROUTE_STREAMTV = '/streamtv';
export const ROUTE_UNSUBSCRIBE = '/unsubscribe';
export const ROUTE_START = '/start';
export const ROUTE_GET_STARTED = '/get_started';
export const ROUTE_START_NOW = '/start-now';
export const ROUTE_VIDEO_MIGRATION = '/video-migration';
export const ROUTE_FIND_MY_BUNDLE = '/find-my-bundle';
export const ROUTE_CHANNELS = '/channels';
export const ROUTE_CHANNELS_WITH_ID = '/channels/:unique_id';
export const ROUTE_RESULTS = '/results';
export const ROUTE_RESULTS_WITH_TOKEN = `${ROUTE_RESULTS}/:unique_id`;
export const ROUTE_RESULTS_INTERNET_OPTION = `${ROUTE_RESULTS_WITH_TOKEN}/internet-option/:internet_option_id`;
export const ROUTE_VIEW_RESULTS = '/view_results';
export const ROUTE_VIEW_RESULTS_WITH_TOKEN = `${ROUTE_VIEW_RESULTS}/:unique_id`;
export const ROUTE_CSR = '/csr';
export const ROUTE_CSR_START_NOW = `${ROUTE_CSR}${ROUTE_START_NOW}`;
export const ROUTE_CSR_CHANNELS = `${ROUTE_CSR}${ROUTE_CHANNELS}`;
export const ROUTE_CSR_CHANNELS_WITH_ID = `${ROUTE_CSR}${ROUTE_CHANNELS_WITH_ID}`;
export const ROUTE_CSR_RESULTS = `${ROUTE_CSR}${ROUTE_RESULTS}`;
export const ROUTE_PROMOTION = '/promotion/:promo_slug';
export const ROUTE_SHARE = '/share';
export const ROUTE_SHARE_WITH_ID = '/share/:unique_id';
export const ROUTE_SEARCH = '/search';
export const ROUTE_DISCOVER = '/discover';
export const ROUTE_DISCOVER_SEARCH = '/discover/search';
export const ROUTE_DISCOVER_GENRE = '/genre/:slug';
export const ROUTE_DISCOVER_APP = `${ROUTE_DISCOVER}/app/:slug`;
export const ROUTE_DISCOVER_MOVIE = `${ROUTE_DISCOVER}/movie/:movie_id`;
export const ROUTE_DISCOVER_SHOW = `${ROUTE_DISCOVER}/show/:show_id`;
export const ROUTE_DISCOVER_CHANNEL = `${ROUTE_DISCOVER}/channel/:slug`;
export const ROUTE_DISCOVER_TUTORIAL = `${ROUTE_DISCOVER}/tutorial`;
export const ROUTE_SHOWS_AND_MOVIES = '/shows-and-movies';
export const ROUTE_SHOWS_AND_MOVIES_DISCOVER = `${ROUTE_SHOWS_AND_MOVIES}/search`;
export const ROUTE_SHOWS_AND_MOVIES_MOVIE = `${ROUTE_SHOWS_AND_MOVIES}/movie/:movie_id`;
export const ROUTE_SHOWS_AND_MOVIES_SHOW = `${ROUTE_SHOWS_AND_MOVIES}/show/:show_id`;
export const ROUTE_SHARED_WATCH_LIST = '/watchlists/:id';
export const ROUTE_PROFILE = '/profile';
export const ROUTE_PROFILE_DETAILS = `${ROUTE_PROFILE}/details`;
export const ROUTE_PROFILE_MY_RESULTS = `${ROUTE_PROFILE}/my-results`;
export const ROUTE_PROFILE_MY_APPS = `${ROUTE_PROFILE}/my-apps`;
export const ROUTE_PROFILE_MY_APPS_LIST = `${ROUTE_PROFILE_MY_APPS}/:content`; // (have)
export const ROUTE_PROFILE_WATCHLISTS = `${ROUTE_PROFILE}/watchlists`;
export const ROUTE_PROFILE_WATCHLISTS_DETAILS = `${ROUTE_PROFILE_WATCHLISTS}/:id`;
export const ROUTE_PROFILE_WN_RESULTS = `${ROUTE_PROFILE}/watch-next-results`;
export const ROUTE_PROFILE_SUBSCRIPTION_DETAIL = `${ROUTE_PROFILE}/subscription-details/:slug`;
export const ROUTE_WATCH_NEXT_TUTORIAL = '/watch-next-tutorial';
export const ROUTE_WATCH_NEXT = '/watch-next';
export const ROUTE_WATCH_NEXT_EDIT_WITH_TOKEN = `${ROUTE_WATCH_NEXT}/edit/:wn_id`;
export const ROUTE_WATCH_NEXT_WITH_TOKEN = `${ROUTE_WATCH_NEXT}/:wn_id`;
export const ROUTE_WATCH_NEXT_VOTE_RESULTS = `${ROUTE_WATCH_NEXT}/results/:wn_id`;
export const ROUTE_SUBSCRIPTION = '/subscription/:slug';
export const ROUTE_STYLEGUIDE = '/styleguide';
export const ROUTE_STYLEGUIDE_STATIC_PAGES = `${ROUTE_STYLEGUIDE}/static-pages`;
export const ROUTE_LOGIN = '/login';
export const ROUTE_SOCIAL_LOGIN = '/social-login';
export const ROUTE_PRIVACY_POLICY = '/privacy-policy';
export const ROUTE_TERMS_AND_CONDITIONS = '/terms-and-conditions';
export const ROUTE_T_AND_C = '/t-and-c';
export const ROUTE_HOW_IT_WORKS = '/how-it-works';
export const ROUTE_ISP_PARTNERS = '/broadband-partners';
export const ROUTE_STREAMING_PARTNERS = '/streaming-partners';
export const ROUTE_ABOUT = '/about';
export const ROUTE_FAQ = '/faq';
export const ROUTE_CAREERS = '/careers';
export const ROUTE_CONTACT_US = '/contact-us';
export const ROUTE_CAREERS_POSITION = `${ROUTE_CAREERS}/:slug`;
export const ROUTE_NO_APP_FOUND = '/no-app-found';
export const ROUTE_BLOG = '/insights';
export const ROUTE_BLOG_POST = `${ROUTE_BLOG}/:slug`;
export const ROUTE_BLOG_AUTHOR = `${ROUTE_BLOG}/author/:slug`;
export const ROUTE_BLOG_CATEGORY = `${ROUTE_BLOG}/category/:slug`;
export const ROUTE_NEWS = '/news';
export const ROUTE_NEWS_POST = `${ROUTE_NEWS}/:slug`;
export const ROUTE_NEWS_AUTHOR = `${ROUTE_NEWS}/author/:slug`;
export const ROUTE_NEWS_CATEGORY = `${ROUTE_NEWS}/category/:slug`;
export const ROUTE_WP_PAGE = '/pages/:slug';
export const ROUTE_WP_BLACK_FRIDAY = '/pages/black-friday';
export const ROUTE_WP_NFL = '/pages/nfl';

export const ROUTE_BUY_APP = '/buy/:slug';
export const ROUTE_PLAN_DETAILS = '/plan/:bundle_id';
export const ROUTE_SLING_AIR_TV_LANDING = '/sling-airtv';

export const ROUTE_SLING_AIR_TV = '/sling-airtv/:bundle_id';
export const ROUTE_INTERNET_OFFERS = '/internet-offers';
export const ROUTE_INTERNET_OFFERS_QUESTIONS = `${ROUTE_INTERNET_OFFERS}/questions`;
export const ROUTE_INTERNET_OFFERS_RESULTS = `${ROUTE_INTERNET_OFFERS}/results`;
export const ROUTE_INTERNET_OFFERS_RESULTS_WITH_TOKEN = `${ROUTE_INTERNET_OFFERS_RESULTS}/:io_id`;
export const ROUTE_EMMY_AWARDS = '/emmy-awards';
export const ROUTE_BUSINESS_APPS = '/business-apps';
export const ROUTE_MAINTENANCE = '/maintenance';
export const ROUTE_CONFIRMATION_ISP = '/isp-confirmation';
export const ROUTE_STREAMING_REWARDS = '/streaming-rewards/:slug';
export const ROUTE_STREAMING_REWARDS_CLAIM = `${ROUTE_STREAMING_REWARDS}/claim`;
export const ROUTE_STREAMING_REWARDS_INSTRUCTION = `${ROUTE_STREAMING_REWARDS}/instruction/:app_slug`;
export const ROUTE_STREAMING_REWARDS_FUNDS = `${ROUTE_STREAMING_REWARDS}/funds`;
export const ROUTE_STREAMING_REWARDS_IMAGE = `${ROUTE_STREAMING_REWARDS}/how-it-works-image`;
export const ROUTE_STREAMING_CHOICE_LANDING = '/streaming-choice-landing';
export const ROUTE_STREAMING_CHOICE = '/streaming-choice/:slug';
export const ROUTE_STREAMING_CHOICE_CLAIM = `${ROUTE_STREAMING_CHOICE}/claim`;
export const ROUTE_STREAMING_CHOICE_INSTRUCTION = `${ROUTE_STREAMING_CHOICE}/instruction/:app_slug`;
export const ROUTE_STREAMING_CHOICE_FUNDS = `${ROUTE_STREAMING_CHOICE}/funds`;
export const ROUTE_STREAMING_CHOICE_IMAGE = `${ROUTE_STREAMING_CHOICE}/how-it-works-image`;
export const ROUTE_PAYMENT_HISTORY = '/payment-history';
export const ROUTE_CARD_BALANCE = '/card-balance';
export const ROUTE_CHANNEL_LINEUP = '/channel-lineup/:slug';
export const ROUTE_CSR_CHANNEL_LINEUP = '/csr-channel-lineup/:slug';
export const ROUTE_SLING_BEST_PACKAGE = '/sling-best-plan';

// REDIRECTS
export const ROUTE_DEALS = '/deals';
export const ROUTE_HULU = '/hulu';
export const ROUTE_HULU_LIVE_ALT = '/hulu-live-alternative';
export const ROUTE_STARZ = '/starz';
export const ROUTE_CYBER_MONDAY = '/cyber-monday';
export const ROUTE_BLACK_FRIDAY = '/black-friday';
export const ROUTE_HOLIDAY_GIFTS = '/holiday-gifts';
export const ROUTE_STARRY_TEST = '/starry_test';
export const ROUTE_ISP = '/isp';
export const ROUTE_START_TEST = '/starttest';
export const ROUTE_START_OLD_TEST = '/startoldtest';
export const ROUTE_FORM = '/form';
export const ROUTE_FORM_RESULTS = '/formresults';
export const ROUTE_REDIRECT = '/rdrct';
export const ROUTE_CHANNELS_NEED = '/channel_need';
export const ROUTE_LOADING = '/loading';
export const ROUTE_RUN_SCHED_TASKS = '/run_sched_tasks';
export const ROUTE_TEST_STUFF = '/test-stuff';
export const ROUTE_CHILDREN = '/children';
export const ROUTE_ROKU_DB = '/roku_db';
export const ROUTE_ROKU_DB_APPS = '/roku_db_apps';
export const ROUTE_ROKU_DB_APP = '/roku_db_app';
export const ROUTE_TEST_HOME = '/test_home';
export const ROUTE_ERROR_LOG = '/errorlog';
export const ROUTE_RESET_IDENTIFY = '/reset_identify';
export const ROUTE_KIDS = '/kids';
export const ROUTE_GUIDEBOX = '/guidebox';
export const ROUTE_PS_VUE_ALT = '/playstation-vue-alternative';
export const ROUTE_FREE_AGREEMENT = '/free-agreement';
export const ROUTE_MANAGE_ACCOUNT = '/manage-account';
export const ROUTE_LIVE_TV = '/live-tv';
export const ROUTE_PROFILE_WATCH_LIST = `${ROUTE_PROFILE}/watch-list`;
export const ROUTE_PROFILE_WL_MOVIES = `${ROUTE_PROFILE}/watch-list/movies`;
export const ROUTE_PROFILE_WL_SHOWS = `${ROUTE_PROFILE}/watch-list/shows`;
export const ROUTE_PROFILE_WL_MOVIES_CONTENT = `${ROUTE_PROFILE_WL_MOVIES}/:content`; // (watched|want-to-watch)
export const ROUTE_PROFILE_WL_SHOWS_CONTENT = `${ROUTE_PROFILE_WL_SHOWS}/:content`; // (watched|want-to-watch|watching)
export const ROUTE_GIFT = '/gift';

// LANDINGS
export const ROUTE_DISNEY_PLUS_BUNDLE = '/disney-plus-bundle';
export const ROUTE_STARRY = '/starry';
export const ROUTE_TESTBUTTON = '/test-button';
export const ROUTE_T_AND_CURIOSITY = '/t-and-c-curiosity';
export const ROUTE_FREE_TV = '/free-tv';
export const ROUTE_MOBILE_APP = '/app';
export const ROUTE_MOBILE_APP_IMAGE = `${ROUTE_MOBILE_APP}/image`;

// PARTNER ROUTES
export const ROUTE_PARTNER = `/:partner`;
export const ROUTE_PARTNER_ROOT_HUB = `/:partner/hub`;
export const ROUTE_PARTNER_HOME = `/:partner${ROUTE_HOME}`;
export const ROUTE_PARTNER_TV = `${ROUTE_PARTNER}${ROUTE_TV}`;
export const ROUTE_PARTNER_STREAMTV = `${ROUTE_PARTNER}${ROUTE_STREAMTV}`;
export const ROUTE_PARTNER_SEARCH = `/:partner${ROUTE_SEARCH}`;
export const ROUTE_PARTNER_DISCOVER = `/:partner${ROUTE_DISCOVER}`;
export const ROUTE_PARTNER_DISCOVER_SEARCH = `/:partner${ROUTE_DISCOVER_SEARCH}`;
export const ROUTE_PARTNER_DISCOVER_APP = `/:partner${ROUTE_DISCOVER_APP}`;
export const ROUTE_PARTNER_DISCOVER_MOVIE = `/:partner${ROUTE_DISCOVER_MOVIE}`;
export const ROUTE_PARTNER_DISCOVER_SHOW = `/:partner${ROUTE_DISCOVER_SHOW}`;
export const ROUTE_PARTNER_DISCOVER_CHANNEL = `/:partner${ROUTE_DISCOVER_CHANNEL}`;
export const ROUTE_PARTNER_START = `/:partner${ROUTE_START}`;
export const ROUTE_PARTNER_VIDEO_MIGRATION = `/:partner${ROUTE_VIDEO_MIGRATION}`;
export const ROUTE_PARTNER_UNSUBSCRIBE = `/:partner${ROUTE_UNSUBSCRIBE}`;
export const ROUTE_PARTNER_FORM = '/partner/:partner';
export const ROUTE_PARTNER_CSR = '/:partner/csr';
export const ROUTE_PARTNER_PROMOTION = `/:partner${ROUTE_PROMOTION}`;
export const ROUTE_PARTNER_CSR_FORM = `${ROUTE_CSR}${ROUTE_PARTNER_FORM}`;
export const ROUTE_PARTNER_CHANNELS = `/:partner${ROUTE_CHANNELS}`;
export const ROUTE_PARTNER_CHANNELS_WITH_ID = `/:partner${ROUTE_CHANNELS_WITH_ID}`;
export const ROUTE_PARTNER_RESULTS = `/:partner${ROUTE_RESULTS}`;
export const ROUTE_PARTNER_RESULTS_WITH_TOKEN = `/:partner${ROUTE_RESULTS_WITH_TOKEN}`;
export const ROUTE_PARTNER_SHOWS_AND_MOVIES = `/:partner${ROUTE_SHOWS_AND_MOVIES}`;
export const ROUTE_PARTNER_SHOWS_AND_MOVIES_DISCOVER = `/:partner${ROUTE_SHOWS_AND_MOVIES_DISCOVER}`;
export const ROUTE_PARTNER_SHOWS_AND_MOVIES_MOVIE = `/:partner${ROUTE_SHOWS_AND_MOVIES_MOVIE}`;
export const ROUTE_PARTNER_SHOWS_AND_MOVIES_SHOW = `/:partner${ROUTE_SHOWS_AND_MOVIES_SHOW}`;
export const ROUTE_PARTNER_SHARED_WATCH_LIST = `:partner${ROUTE_SHARED_WATCH_LIST}`;
export const ROUTE_PARTNER_PROFILE = `/:partner${ROUTE_PROFILE}`;
export const ROUTE_PARTNER_PROFILE_DETAILS = `/:partner${ROUTE_PROFILE_DETAILS}`;
export const ROUTE_PARTNER_PROFILE_MY_RESULTS = `/:partner${ROUTE_PROFILE_MY_RESULTS}`;
export const ROUTE_PARTNER_PROFILE_MY_APPS = `/:partner${ROUTE_PROFILE_MY_APPS}`;
export const ROUTE_PARTNER_PROFILE_MY_APPS_LIST = `/:partner${ROUTE_PROFILE_MY_APPS_LIST}`;
export const ROUTE_PARTNER_PROFILE_WATCHLISTS = `/:partner${ROUTE_PROFILE_WATCHLISTS}`;
export const ROUTE_PARTNER_PROFILE_WATCHLISTS_DETAILS = `/:partner${ROUTE_PROFILE_WATCHLISTS_DETAILS}`;
export const ROUTE_PARTNER_PROFILE_WN_RESULTS = `/:partner${ROUTE_PROFILE_WN_RESULTS}`;
export const ROUTE_PARTNER_PROFILE_SUBSCRIPTION_DETAIL = `/:partner${ROUTE_PROFILE_SUBSCRIPTION_DETAIL}`;
export const ROUTE_PARTNER_WATCH_NEXT_TUTORIAL = `/:partner${ROUTE_WATCH_NEXT_TUTORIAL}`;
export const ROUTE_PARTNER_WATCH_NEXT = `/:partner${ROUTE_WATCH_NEXT}`;
export const ROUTE_PARTNER_WATCH_NEXT_EDIT_WITH_TOKEN = `/:partner${ROUTE_WATCH_NEXT_EDIT_WITH_TOKEN}`;
export const ROUTE_PARTNER_WATCH_NEXT_WITH_TOKEN = `/:partner${ROUTE_WATCH_NEXT_WITH_TOKEN}`;
export const ROUTE_PARTNER_WATCH_NEXT_VOTE_RESULTS = `/:partner${ROUTE_WATCH_NEXT_VOTE_RESULTS}`;
export const ROUTE_PARTNER_SUBSCRIPTION = `/:partner${ROUTE_SUBSCRIPTION}`;
export const ROUTE_PARTNER_SLING_AIR_TV_LANDING = `/:partner${ROUTE_SLING_AIR_TV_LANDING}`;
export const ROUTE_PARTNER_SLING_AIR_TV = `/:partner${ROUTE_SLING_AIR_TV}`;
export const ROUTE_PARTNER_BLOG = `/:partner${ROUTE_BLOG}`;
export const ROUTE_PARTNER_BLOG_POST = `${ROUTE_PARTNER_BLOG}/:slug`;
export const ROUTE_PARTNER_BLOG_AUTHOR = `${ROUTE_PARTNER_BLOG}/author/:slug`;
export const ROUTE_PARTNER_BLOG_CATEGORY = `${ROUTE_PARTNER_BLOG}/category/:slug`;
export const ROUTE_PARTNER_NEWS = `/:partner${ROUTE_NEWS}`;
export const ROUTE_PARTNER_NEWS_POST = `${ROUTE_PARTNER_NEWS}/:slug`;
export const ROUTE_PARTNER_NEWS_AUTHOR = `${ROUTE_PARTNER_NEWS}/author/:slug`;
export const ROUTE_PARTNER_NEWS_CATEGORY = `${ROUTE_PARTNER_NEWS}/category/:slug`;
export const ROUTE_PARTNER_WP_PAGE = `/:partner${ROUTE_WP_PAGE}`;
export const ROUTE_PARTNER_STYLEGUIDE = `/:partner${ROUTE_STYLEGUIDE}`;
export const ROUTE_PARTNER_STYLEGUIDE_STATIC_PAGES = `/:partner${ROUTE_STYLEGUIDE_STATIC_PAGES}`;
export const ROUTE_PARTNER_LOGIN = `/:partner${ROUTE_LOGIN}`;
export const ROUTE_PARTNER_BUY_APP = '/:partner/buy/:slug';
export const ROUTE_PARTNER_BUY_APP_2 = '/buy/:slug/:partner';
export const ROUTE_PARTNER_SOCIAL_LOGIN = `/:partner${ROUTE_SOCIAL_LOGIN}`;
export const ROUTE_PARTNER_EMMY_AWARDS = `/:partner${ROUTE_EMMY_AWARDS}`;
export const ROUTE_PARTNER_BUSINESS_APPS = `/:partner${ROUTE_BUSINESS_APPS}`;
export const ROUTE_PARTNER_404 = `/:partner${ROUTE_404}`;
export const ROUTE_PATNER_NO_APP_FOUND = `/:partner${ROUTE_NO_APP_FOUND}`;
export const ROUTE_PARTNER_CONFIRMATION_ISP = `/:partner${ROUTE_CONFIRMATION_ISP}`;
export const ROUTE_PARTNER_STREAMING_REWARDS = `/:partner${ROUTE_STREAMING_REWARDS}`;
export const ROUTE_PARTNER_STREAMING_REWARDS_CLAIM = `/:partner${ROUTE_STREAMING_REWARDS_CLAIM}`;
export const ROUTE_PARTNER_STREAMING_REWARDS_INSTRUCTION = `/:partner${ROUTE_STREAMING_REWARDS_INSTRUCTION}`;
export const ROUTE_PARTNER_STREAMING_REWARDS_FUNDS = `/:partner${ROUTE_STREAMING_REWARDS_FUNDS}`;
export const ROUTE_PARTNER_STREAMING_REWARDS_IMAGE = `/:partner${ROUTE_STREAMING_REWARDS_IMAGE}`;
export const ROUTE_PARTNER_STREAMING_CHOICE_LANDING = `/:partner${ROUTE_STREAMING_CHOICE_LANDING}`;

export const ROUTE_PARTNER_STREAMING_CHOICE = `/:partner${ROUTE_STREAMING_CHOICE}`;
export const ROUTE_PARTNER_STREAMING_CHOICE_CLAIM = `/:partner${ROUTE_STREAMING_CHOICE_CLAIM}`;
export const ROUTE_PARTNER_STREAMING_CHOICE_INSTRUCTION = `/:partner${ROUTE_STREAMING_CHOICE_INSTRUCTION}`;
export const ROUTE_PARTNER_STREAMING_CHOICE_FUNDS = `/:partner${ROUTE_STREAMING_CHOICE_FUNDS}`;
export const ROUTE_PARTNER_STREAMING_CHOICE_IMAGE = `/:partner${ROUTE_STREAMING_CHOICE_IMAGE}`;
export const ROUTE_PARTNER_ROUTE_MANAGE_ACCOUNT = `/:partner${ROUTE_MANAGE_ACCOUNT}`;
export const ROUTE_PARTNER_LIVE_TV = `/:partner${ROUTE_LIVE_TV}`;
export const ROUTE_PARTNER_CHANNEL_LINEUP = `/:partner${ROUTE_CHANNEL_LINEUP}`;
export const ROUTE_PARTNER_CSR_CHANNEL_LINEUP = `/:partner${ROUTE_CSR_CHANNEL_LINEUP}`;
export const ROUTE_PARTNER_SLING_BEST_PACKAGE = `/:partner${ROUTE_SLING_BEST_PACKAGE}`;
export const ROUTE_PARTNER_FREE_TV = `/:partner${ROUTE_FREE_TV}`;
export const ROUTE_PARTNER_MOBILE_APP = `/:partner${ROUTE_MOBILE_APP}`;
export const ROUTE_PARTNER_MOBILE_APP_IMAGE = `/:partner${ROUTE_MOBILE_APP_IMAGE}`;

// PARTNER REDIRECTS
export const ROUTE_PARTNER_PROFILE_WATCH_LIST = `/:partner${ROUTE_PROFILE_WATCH_LIST}`;
export const ROUTE_PARTNER_PROFILE_WL_MOVIES = `/:partner${ROUTE_PROFILE_WL_MOVIES}`;
export const ROUTE_PARTNER_PROFILE_WL_SHOWS = `/:partner${ROUTE_PROFILE_WL_SHOWS}`;
export const ROUTE_PARTNER_PROFILE_WL_MOVIES_CONTENT = `/:partner${ROUTE_PROFILE_WL_MOVIES_CONTENT}`;
export const ROUTE_PARTNER_PROFILE_WL_SHOWS_CONTENT = `/:partner${ROUTE_PROFILE_WL_SHOWS_CONTENT}`;
export const ROUTE_PARTNER_GIFT = `/:partner${ROUTE_GIFT}`;

export const mybundleRoutes = [
  ROUTE_404,
  ROUTE_HOME,
  ROUTE_TV,
  ROUTE_STREAMTV,
  ROUTE_UNSUBSCRIBE,
  ROUTE_START,
  ROUTE_GET_STARTED,
  ROUTE_START_NOW,
  ROUTE_VIDEO_MIGRATION,
  ROUTE_FIND_MY_BUNDLE,
  ROUTE_CHANNELS,
  ROUTE_CHANNELS_WITH_ID,
  ROUTE_RESULTS,
  ROUTE_RESULTS_WITH_TOKEN,
  ROUTE_RESULTS_INTERNET_OPTION,
  ROUTE_VIEW_RESULTS,
  ROUTE_VIEW_RESULTS_WITH_TOKEN,
  ROUTE_CSR,
  ROUTE_CSR_START_NOW,
  ROUTE_CSR_CHANNELS,
  ROUTE_CSR_CHANNELS_WITH_ID,
  ROUTE_CSR_RESULTS,
  ROUTE_SHARE,
  ROUTE_SHARE_WITH_ID,
  ROUTE_SEARCH,
  ROUTE_DISCOVER,
  ROUTE_DISCOVER_SEARCH,
  ROUTE_DISCOVER_GENRE,
  ROUTE_DISCOVER_APP,
  ROUTE_DISCOVER_MOVIE,
  ROUTE_DISCOVER_SHOW,
  ROUTE_DISCOVER_CHANNEL,
  ROUTE_DISCOVER_TUTORIAL,
  ROUTE_SHOWS_AND_MOVIES,
  ROUTE_SHOWS_AND_MOVIES_DISCOVER,
  ROUTE_SHOWS_AND_MOVIES_MOVIE,
  ROUTE_SHOWS_AND_MOVIES_SHOW,
  ROUTE_PROMOTION,
  ROUTE_SHARED_WATCH_LIST,
  ROUTE_PROFILE,
  ROUTE_PROFILE_DETAILS,
  ROUTE_PROFILE_MY_RESULTS,
  ROUTE_PROFILE_MY_APPS,
  ROUTE_PROFILE_MY_APPS_LIST,
  ROUTE_PROFILE_WATCHLISTS,
  ROUTE_PROFILE_WATCHLISTS_DETAILS,
  ROUTE_PROFILE_WN_RESULTS,
  ROUTE_PROFILE_SUBSCRIPTION_DETAIL,
  ROUTE_WATCH_NEXT_TUTORIAL,
  ROUTE_WATCH_NEXT,
  ROUTE_WATCH_NEXT_EDIT_WITH_TOKEN,
  ROUTE_WATCH_NEXT_WITH_TOKEN,
  ROUTE_WATCH_NEXT_VOTE_RESULTS,
  ROUTE_SUBSCRIPTION,
  ROUTE_STYLEGUIDE,
  ROUTE_STYLEGUIDE_STATIC_PAGES,
  ROUTE_LOGIN,
  ROUTE_PRIVACY_POLICY,
  ROUTE_TERMS_AND_CONDITIONS,
  ROUTE_T_AND_C,
  ROUTE_HOW_IT_WORKS,
  ROUTE_ISP_PARTNERS,
  ROUTE_STREAMING_PARTNERS,
  ROUTE_ABOUT,
  ROUTE_FAQ,
  ROUTE_CAREERS,
  ROUTE_CAREERS_POSITION,
  ROUTE_CONTACT_US,
  ROUTE_NO_APP_FOUND,
  ROUTE_BLOG,
  ROUTE_BLOG_POST,
  ROUTE_BLOG_AUTHOR,
  ROUTE_BLOG_CATEGORY,
  ROUTE_NEWS,
  ROUTE_NEWS_POST,
  ROUTE_NEWS_AUTHOR,
  ROUTE_NEWS_CATEGORY,
  ROUTE_WP_PAGE,
  ROUTE_BUY_APP,
  ROUTE_PLAN_DETAILS,
  ROUTE_SLING_AIR_TV_LANDING,
  ROUTE_SLING_AIR_TV,
  ROUTE_INTERNET_OFFERS,
  ROUTE_INTERNET_OFFERS_QUESTIONS,
  ROUTE_INTERNET_OFFERS_RESULTS,
  ROUTE_INTERNET_OFFERS_RESULTS_WITH_TOKEN,
  ROUTE_DISNEY_PLUS_BUNDLE,
  ROUTE_STARRY,
  ROUTE_TESTBUTTON,
  ROUTE_T_AND_CURIOSITY,
  ROUTE_FREE_TV,
  ROUTE_MOBILE_APP,
  ROUTE_MOBILE_APP_IMAGE,
  ROUTE_EMMY_AWARDS,
  ROUTE_ROOT,
  ROUTE_BUSINESS_APPS,
  ROUTE_MAINTENANCE,
  ROUTE_CONFIRMATION_ISP,
  ROUTE_STREAMING_REWARDS,
  ROUTE_STREAMING_REWARDS_CLAIM,
  ROUTE_STREAMING_REWARDS_INSTRUCTION,
  ROUTE_STREAMING_REWARDS_FUNDS,
  ROUTE_STREAMING_REWARDS_IMAGE,
  ROUTE_STREAMING_CHOICE_LANDING,
  ROUTE_STREAMING_CHOICE,
  ROUTE_STREAMING_CHOICE_CLAIM,
  ROUTE_STREAMING_CHOICE_INSTRUCTION,
  ROUTE_STREAMING_CHOICE_FUNDS,
  ROUTE_STREAMING_CHOICE_IMAGE,
  ROUTE_PAYMENT_HISTORY,
  ROUTE_CHANNEL_LINEUP,
  ROUTE_CSR_CHANNEL_LINEUP,
  ROUTE_SLING_BEST_PACKAGE,

  // REDIRECTS
  ROUTE_DEALS,
  ROUTE_HULU,
  ROUTE_HULU_LIVE_ALT,
  ROUTE_STARZ,
  ROUTE_CYBER_MONDAY,
  ROUTE_BLACK_FRIDAY,
  ROUTE_HOLIDAY_GIFTS,
  ROUTE_STARRY_TEST,
  ROUTE_ISP,
  ROUTE_START_TEST,
  ROUTE_START_OLD_TEST,
  ROUTE_FORM,
  ROUTE_FORM_RESULTS,
  ROUTE_REDIRECT,
  ROUTE_CHANNELS_NEED,
  ROUTE_LOADING,
  ROUTE_RUN_SCHED_TASKS,
  ROUTE_TEST_STUFF,
  ROUTE_CHILDREN,
  ROUTE_ROKU_DB,
  ROUTE_ROKU_DB_APPS,
  ROUTE_ROKU_DB_APP,
  ROUTE_TEST_HOME,
  ROUTE_ERROR_LOG,
  ROUTE_RESET_IDENTIFY,
  ROUTE_KIDS,
  ROUTE_GUIDEBOX,
  ROUTE_PS_VUE_ALT,
  ROUTE_LIVE_TV,
  ROUTE_FREE_AGREEMENT,
  ROUTE_CARD_BALANCE,
  ROUTE_MANAGE_ACCOUNT,
  ROUTE_PROFILE_WATCH_LIST,
  ROUTE_PROFILE_WL_MOVIES,
  ROUTE_PROFILE_WL_SHOWS,
  ROUTE_PROFILE_WL_MOVIES_CONTENT,
  ROUTE_PROFILE_WL_SHOWS_CONTENT,
  ROUTE_GIFT,
];

export const breadcrumbNameMap = {
  [ROUTE_ROOT]: 'common.menu.home',
  [ROUTE_PRIVACY_POLICY]: 'privacyPolicy.title',
  [ROUTE_TERMS_AND_CONDITIONS]: 'termsAndConditions.title',
  [ROUTE_DISCOVER]: 'common.menu.explore',
  [ROUTE_DISCOVER_TUTORIAL]: 'common.menu.exploreTutorial',
  [ROUTE_HOW_IT_WORKS]: 'common.menu.howItWorks',
  [ROUTE_ABOUT]: 'common.menu.about',
  [ROUTE_FAQ]: 'common.menu.faq',
};

export const partnerRoutes = [
  ROUTE_PARTNER_ROOT_HUB,
  ROUTE_PARTNER_HOME,
  ROUTE_PARTNER_TV,
  ROUTE_PARTNER_STREAMTV,
  ROUTE_PARTNER_SEARCH,
  ROUTE_PARTNER_DISCOVER,
  ROUTE_PARTNER_DISCOVER_SEARCH,
  ROUTE_PARTNER_DISCOVER_APP,
  ROUTE_PARTNER_DISCOVER_MOVIE,
  ROUTE_PARTNER_DISCOVER_SHOW,
  ROUTE_PARTNER_DISCOVER_CHANNEL,
  ROUTE_PARTNER_UNSUBSCRIBE,
  ROUTE_PARTNER_START,
  ROUTE_PARTNER_VIDEO_MIGRATION,
  ROUTE_PARTNER_FORM,
  ROUTE_PARTNER_CSR,
  ROUTE_PARTNER_CSR_FORM,
  ROUTE_PARTNER_CHANNELS,
  ROUTE_PARTNER_CHANNELS_WITH_ID,
  ROUTE_PARTNER_RESULTS,
  ROUTE_PARTNER_RESULTS_WITH_TOKEN,
  ROUTE_PARTNER_SHOWS_AND_MOVIES,
  ROUTE_PARTNER_SHOWS_AND_MOVIES_DISCOVER,
  ROUTE_PARTNER_SHOWS_AND_MOVIES_MOVIE,
  ROUTE_PARTNER_SHOWS_AND_MOVIES_SHOW,
  ROUTE_PARTNER_SHARED_WATCH_LIST,
  ROUTE_PARTNER_PROFILE,
  ROUTE_PARTNER_PROFILE_DETAILS,
  ROUTE_PARTNER_PROFILE_MY_RESULTS,
  ROUTE_PARTNER_PROFILE_MY_APPS,
  ROUTE_PARTNER_PROFILE_MY_APPS_LIST,
  ROUTE_PARTNER_PROFILE_WATCHLISTS,
  ROUTE_PARTNER_PROFILE_WATCHLISTS_DETAILS,
  ROUTE_PARTNER_PROFILE_WN_RESULTS,
  ROUTE_PARTNER_PROFILE_SUBSCRIPTION_DETAIL,
  ROUTE_PARTNER_PROMOTION,
  ROUTE_PARTNER_WATCH_NEXT_TUTORIAL,
  ROUTE_PARTNER_WATCH_NEXT,
  ROUTE_PARTNER_WATCH_NEXT_EDIT_WITH_TOKEN,
  ROUTE_PARTNER_WATCH_NEXT_WITH_TOKEN,
  ROUTE_PARTNER_WATCH_NEXT_VOTE_RESULTS,
  ROUTE_PARTNER_SUBSCRIPTION,
  ROUTE_PARTNER_SLING_AIR_TV,
  ROUTE_PARTNER_SLING_AIR_TV_LANDING,
  ROUTE_PARTNER_BLOG,
  ROUTE_PARTNER_BLOG_POST,
  ROUTE_PARTNER_BLOG_AUTHOR,
  ROUTE_PARTNER_BLOG_CATEGORY,
  ROUTE_PARTNER_NEWS,
  ROUTE_PARTNER_NEWS_POST,
  ROUTE_PARTNER_NEWS_AUTHOR,
  ROUTE_PARTNER_NEWS_CATEGORY,
  ROUTE_PARTNER_WP_PAGE,
  ROUTE_PARTNER_EMMY_AWARDS,
  ROUTE_PARTNER_BUSINESS_APPS,
  ROUTE_PARTNER_STYLEGUIDE,
  ROUTE_PARTNER_STYLEGUIDE_STATIC_PAGES,
  ROUTE_PARTNER_LOGIN,
  ROUTE_PARTNER_SOCIAL_LOGIN,
  ROUTE_PARTNER_BUY_APP,
  ROUTE_PARTNER_BUY_APP_2,
  ROUTE_PARTNER_404,
  ROUTE_PATNER_NO_APP_FOUND,
  ROUTE_PARTNER,
  ROUTE_PARTNER_CONFIRMATION_ISP,
  ROUTE_PARTNER_STREAMING_REWARDS,
  ROUTE_PARTNER_STREAMING_REWARDS_CLAIM,
  ROUTE_PARTNER_STREAMING_REWARDS_INSTRUCTION,
  ROUTE_PARTNER_STREAMING_REWARDS_FUNDS,
  ROUTE_PARTNER_STREAMING_REWARDS_IMAGE,
  ROUTE_PARTNER_STREAMING_CHOICE_LANDING,
  ROUTE_PARTNER_STREAMING_CHOICE,
  ROUTE_PARTNER_STREAMING_CHOICE_CLAIM,
  ROUTE_PARTNER_STREAMING_CHOICE_INSTRUCTION,
  ROUTE_PARTNER_STREAMING_CHOICE_FUNDS,
  ROUTE_PARTNER_STREAMING_CHOICE_IMAGE,
  ROUTE_PARTNER_ROUTE_MANAGE_ACCOUNT,
  ROUTE_PARTNER_CHANNEL_LINEUP,
  ROUTE_PARTNER_CSR_CHANNEL_LINEUP,
  ROUTE_PARTNER_SLING_BEST_PACKAGE,
  ROUTE_PARTNER_FREE_TV,
  ROUTE_PARTNER_MOBILE_APP,
  ROUTE_PARTNER_MOBILE_APP_IMAGE,

  // REDIRECTS
  ROUTE_PARTNER_LIVE_TV,
  ROUTE_PARTNER_PROFILE_WATCH_LIST,
  ROUTE_PARTNER_PROFILE_WL_MOVIES,
  ROUTE_PARTNER_PROFILE_WL_SHOWS,
  ROUTE_PARTNER_PROFILE_WL_MOVIES_CONTENT,
  ROUTE_PARTNER_PROFILE_WL_SHOWS_CONTENT,
  ROUTE_PARTNER_GIFT,
];
